@import "../../screen-sizes.scss";

.dropdown {
  &-item {
    padding: 14px 0;

    &:not(.active) {
      &.friend {
        border-bottom: 1px solid #0062c4;
      }
      &.flirt {
        border-bottom: 1px solid #6542dd;
      }
      &.fun {
        border-bottom: 1px solid #a74cb8;
      }
    }

    &-tab {
      &-button {
        button {
          display: flex;
          justify-content: space-between;
          background-color: transparent;
          border: none;
          width: 100%;
          padding: 0;
        }
      }
    }

    &-content {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}
