@import '../../screen-sizes.scss';

.settings {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-sizing: border-box;
    padding: 0 130px 60px;
}

.account-management-element {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .delete-profile-container {
        max-width: 510px;
        display: flex;
        flex-direction: column;
        gap: 22px;
        align-items: center;

        .profile-title {
            font-weight: 500;
            font-size: 24px;
            line-height: 31.2px;
            margin: 0;
        }

        .profile-base-text {
            max-width: 342px;
            font-size: 16px;
            margin: 0;
            text-align: center;
        }

        .btns-container {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .btn {
                background: none;
                border-radius: 60px;
                font-size: 18px;
                font-weight: 500;
                padding: 18px 20px;
                min-width: 120px;
                color: #6542DD;
                cursor: pointer;
                outline: none;
                border: 1px solid #6542DD;
                &:focus-visible {
                    outline: none;
                }
            }

            .delete-btn {
                background-color: #F03131;
                color: #ffffff;
                border-color: #F03131;
            }

        }
    }

}

.account-management-element-label {
    color: rgba(28, 25, 38, 1);
    font-size: 20px;
    line-height: 22px;
    cursor: pointer;
}

.account-management-element-label span {
    margin-left: 7px;
}

.account-management-element:first-child {
    margin-top: 0;
}

.account-management-email-label {
    color: rgba(85, 81, 101, 1);
    font-size: 18px;
    line-height: 25px;
}

.account-management-email-input {
    margin-top: 4px;
    width: 100%;
    height: 56px;
    border: 1px solid rgba(157, 152, 170, 1);
    border-radius: 12px;
    background-color: transparent;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    color: rgba(28, 25, 38, 1);
    font-size: 18px;
    line-height: 24px;
}

.account-management-change-password-link {
    font-weight: 500;
    display: block;
    margin-top: 16px;
    text-decoration: none;
    font-size: 18px;
    line-height: 25px;
    cursor: pointer;
}

.visibility-element {
    margin-top: 24px;
}

.visibility-element:first-child {
    margin-top: 0;
}

.visibility-map {
    width: 100%;
    height: 250px;
    margin-top: 17px;
    border-radius: 16px;
}

.visibility-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 32px;
}

.visibility-bottom-link {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.01em;
    cursor: pointer;
}

.visibility-bottom-button {
    cursor: pointer;
    margin-left: auto;
    border-radius: 44px;
    height: 57px;
    width: 120px;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.01em;
    box-shadow: none;
    color: #ffffff;
}

.visibility-location-select .location-select {
    margin-top: 0;
}

.visibility-location-select .location-select-inner {
    width: 100%;
}

.visibility-location-select .location-select-map {
    width: 100%;
    height: 250px;
    margin-top: 17px;
    border-radius: 16px;
}

.visibility-location-select .location-select-field-input {
    width: calc(100% - 66px);
}

@media only screen and (max-width: $MAX_M_WIDTH) {

    .settings {
        padding: 0 20px 40px 20px;
    }

}
