@import "../../screen-sizes.scss";

.header {
  height: 80px;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;

  .header-logo {
    width: 217px;
    height: 22px;
    display: block;
    text-decoration: none;
  }

  .header-logo-image {
    width: 100%;
    height: 100%;
    display: block;
  }

  .header-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  .header-menu-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .menu-icon-container {
    display: none;
  }

  .header-menu {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .header-menu li {
    margin-left: 16px;
    padding: 12px 8px 12px 8px;
  }

  .header-menu li:first-child {
    margin-left: 0;
  }

  .header-menu li a {
    color: #393545;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
  }
}

.header-menu-apps {
  display: flex;
  flex-direction: row;
  margin-left: 32px;

  &-link {
    height: 40px;
    display: block;

    &:nth-child(2) {
      margin-left: 12px;
    }

  }

}

@media only screen and (max-width: $MAX_M_WIDTH) {
  .header {
    height: 60px;

    .header-menu-container {
      display: none;
    }

    .header-logo-image {
      width: 180px;
    }

    .menu-icon {
      &-container {
        display: flex;
        align-items: center;
      }

      &-button {
        background-color: transparent;
        border: none;
      }
    }
  }
}
