@import '../../screen-sizes.scss';

.page-with-menu-container {
    display: flex;
    flex-direction: row;
    position: relative;
    margin: auto;
}

.page-with-menu {
    padding-top: 32px;
    width: 100%;
    padding-bottom: 32px;
    box-sizing: border-box;
}

.page-with-menu-top {
    padding: 24px 0 24px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 16px;
    background: #ffffff;
}

.page-with-menu-top-back-icon {
    width: 32px;
    height: 32px;
    cursor: pointer;
    left: 32px;
    top: 29px;
    position: absolute;
}

.page-with-menu-top-title {
    color: rgba(28, 25, 38, 1);
    font-size: 32px;
    line-height: 42px;
    font-weight: 500;
}

.page-with-menu-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: #ffffff;
    width: 100%;
}


.page-with-menu-aside {
    width: 276px;
    position: sticky;
    top: 32px;
    left: 0;
}

.page-with-menu-aside-messages {
    width: 359px;
}

.page-with-menu-content {
    width: calc(100% - 276px - 32px);
    margin-left: auto;
}

.page-with-menu-content-messages {
    width: calc(100% - 359px - 32px);
}

.page-without-menu {
    width: 100%;
    display: flex; 
    flex-direction: column;
    box-sizing: border-box;
}

.page-without-menu-inner {
    border-radius: unset;
    background: #ffffff;
    width: 100%;
}

@media only screen and (max-width: $MAX_M_WIDTH) {
    
    .page-with-menu-content-messages {
        padding: 0 20px;
        width: 100%;
    }
    

}