.filters-modal {
  width: 100%;
  max-width: 600px;
}

.filters-modal-title {
  display: block;
  text-align: center;
  color: rgba(28, 25, 38, 1);
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
}

.filters-elements {
  overflow-y: auto;
  margin-top: 24px;
  height: 500px;
}

.filters-element {
  margin-top: 24px;
}

.filters-switcher .switcher-label {
  color: rgb(28, 25, 38);
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}

.filters-element:first-child {
  margin-top: 0;
}

.filters-element-label {
  display: block;
  color: rgba(28, 25, 38, 1);
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}

.filters-element-content {
  margin-top: 16px;
}

.filters-clear-button {
  margin-left: 32px;
  font-size: 18px;
  line-height: 25px;
  font-weight: 500;
  cursor: pointer;
}
