.dialog-modal-icon {
  margin: auto;
  width: 65px;
  height: 65px;
}

.dialog-modal-text {
  text-align: center;
  display: block;
  color: rgba(28, 25, 38, 1);
  font-size: 16px;
  line-height: 23px;
  max-width: 324px;
  margin: 16px auto 0;
}
