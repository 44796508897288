
.content-wrapper {
  width: 320px;

  .btn-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    margin-top: 20px;

    button {
      width: 100%;
    }

    .install-btn {
      font-weight: 500;
    }

    .decline-btn {
      padding: 12px 0px;
      font-size: 14px;
    }

  }

  .install-component {
    text-align: center;
  
    h2 {
      font-size: 22px;
      font-weight: 400;
    }
  
    .install-wrapper {
      margin-top: 30px;
    }
  
  }

  .install-guide-wrapper {

    .install-icon {
      display: flex;
      width: 100%;
      justify-content: center;
    }
 
    h2 {
      font-weight: 400;
      text-align: center;
    }

    .text {
      display: block;
      text-align: center;
      width: 100%;
    }

    .install-img-wrapper {
      margin: 10px 0;
      position: relative;

      .arrow {
        position: absolute;
        width: 50px;

        &-edge {
          right: 88px;
          top: -20px;
        }

        &-chrome {
          right: 150px;
          top: -15px;
        }


      }

      img {
        width: 100%;
      }
    }

  }
  
}