.info {
    margin-left: 12px;
    position: relative;
    width: 24px;
    height: 24px;
}

.info-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    color: #9D98AA;
}

.info-text {
    position: absolute;
    left: -320px;
    bottom: 30px;
    width: 320px;
    display: none;
    padding: 16px 24px;
    box-sizing: border-box;
    color: rgba(57, 53, 69, 1);
    font-size: 14px;
    line-height: 20px;
    background-color: rgba(252, 251, 255, 1);
    border-radius: 16px 16px 0 16px;
    box-shadow: 2px 4px 20px 0 rgba(54, 21, 124, 0.15);
}

.info:hover .info-text {
    display: block;
}

.info:hover .info-icon {
    color: rgb(101, 66, 221);
}
