@import "../../screen-sizes.scss";

.left-menu {
  background-color: #ffffff;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 16px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;

  &-element {
    text-decoration: none;
    margin-bottom: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    padding: 16px 23px 16px 16px;
    border-radius: 50px;

    &:hover {
      background-color: rgba(242, 238, 255, 1);
    }

    &-icon {
      width: 32px;
      height: 32px;
    }

    &-label {
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0;
      color: rgb(57, 53, 69);
      margin-left: 12px;
    }

    &.active {
      background: rgba(234, 228, 255, 1);

      .left-menu-element-label {
        color: rgb(101, 66, 221);
        font-weight: 600;
      }
    }

    &-logout {
      margin-top: 130px;
    }
  }
}

@media only screen and (max-width: $MAX_M_WIDTH) {
  .left-menu {
    margin-top: unset;
    height: 100%;
    justify-content: space-between;

    &-element {
      padding: 12px 19px 12px 12px;
      min-width: max-content;

      &-label {
        font-size: 18px;
        line-height: 22px;
      }

      &-icon {
        width: 28px;
        height: 28px;
      }

      &-logout {
        margin-top: unset;

        img {
          display: none;
        }
      }
    }

    &-bottom {
      margin-top: 24px;
      padding-top: 12px;
    }
  }
}
