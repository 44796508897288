
.main-layount-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-layount-container > .header-container {
  display: flex;
  flex: 0 0 auto;
  height: fit-content;
}

.main-layount-container > .content-container {
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
}

.main-layount-container > .footer-container {
  height: fit-content;
}