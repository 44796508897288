.profile-switcher-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 0;

  &-profile-info {
    display: flex;
    align-items: center;
    padding: 12px 19px 12px 12px;
    border-radius: 32px;
    min-width: max-content;

    &-image {
      border: 1px solid rgb(252, 251, 255);
      border-radius: 32px;
      width: 32px;
      height: 32px;
    }

    &-name {
      color: white;
      margin-left: 8px;
      font-weight: 600;
      font-size: 18px;
    }
  }

  &-profile-picker {
    display: flex;
    align-items: center;
    margin-left: 8px;
    background-color: transparent;
    border: none;
  }

  &-picker {
    &-icon {
      width: 20px;
      height: 20px;
    }

    &-label {
      margin-left: 4px;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0;
    }

    &-arrow {
      margin-left: 4px;
      width: 20px;
      height: 20px;
      transform: rotate(180deg);
    }
  }
}
