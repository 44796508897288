@import '../../screen-sizes.scss';

.on-boarding {
    display: flex;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 170px;
    min-height: calc(100vh - 190px);
    box-sizing: border-box;
    flex-direction: column;
    position: relative;
    margin: auto;

    .on-boarding-title {
        text-align: center;
        margin-top: 32px;
        color: rgba(28, 25, 38, 1);
        font-size: 32px;
        line-height: 36px;
        font-weight: 600;
    }

    .on-boarding-line {
        width: 300px;
        height: 6px;
        margin-top: 75px;
        border-radius: 10px;
        background-color: rgba(234, 228, 255, 1);
    }

    .on-boarding-line-background {
        background: linear-gradient(90deg, #0162C4 0%, #3456D4 11.87%, #4C4EDA 24.37%, #5C4AD8 36.87%, #6F46D6 50.52%, #7444D5 66.67%, #9344C7 83.85%, #A44BB9 100%);
        height: 6px;
        border-radius: 10px;
    }

    .on-boarding-buttons {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
    }

    .on-boarding-button {
        margin-top: 12px;
        width: 265px;
        height: 74px;
        border-radius: 16px;
        background-color: transparent;
        color: rgba(101, 66, 221, 1);
        box-shadow: none;
        border: 1px solid rgba(101, 66, 221, 1);
        font-size: 24px;
        font-weight: 600;
        cursor: pointer;
    }

    .on-boarding-button:first-child {
        margin-top: 0;
    }

    .on-boarding-submit-button {
        margin-top: 31px;
        border-radius: 44px;
        width: 139px;
        height: 57px;
        box-shadow: none;
        background: linear-gradient(90deg, #0162C4 0%, #3456D4 11.87%, #4C4EDA 24.37%, #5C4AD8 36.87%, #6F46D6 50.52%, #7444D5 66.67%, #9344C7 83.85%, #A44BB9 100%);
        font-size: 18px;
        font-weight: 500;
        color: rgba(252, 251, 255, 1);
        border: none;
        cursor: pointer;
    }

    .on-boarding-submit-button:disabled {
        opacity: .35;
        cursor: inherit;;
    }

    .on-boarding-text {
        margin-top: 32px;
        text-align: center;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0;
        max-width: 600px;
    }

    .on-boarding-profile-types {
        margin-top: 32px;
        max-width: 610px;
        width: 100%;
    }

    .on-boarding-profile-type {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 12px;
    }

    .on-boarding-profile-type-inner {
        width: 100%;
        height: 104px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 40px;
        padding-right: 40px;
        box-sizing: border-box;
        border: 1px solid rgba(0, 98, 196, 1);
        border-radius: 16px;
        cursor: pointer;
    }

    .on-boarding-profile-type:first-child {
        margin-top: 0;
    }

    .on-boarding-profile-type-icon {
        width: 32px;
        height: 32px;
        margin-right: 8px;
    }

    .on-boarding-profile-type-check {
        width: 40px;
        height: 40px;
        margin-left: auto;
    }

    .on-boarding-profile-type-label {
        font-size: 24px;
        line-height: 30px;
        font-weight: 600;
    }

    .on-boarding-input-area {
        position: relative;
        margin-top: 32px;
        max-width: 425px;
        width: 100%;
    }

    .on-boarding-input {
        border: 1px solid rgba(157, 152, 170, 1);
        width: 100%;
        max-width: 425px;
        height: 56px;
        border-radius: 12px;
        margin-top: 32px;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
        font-size: 18px;
        color: rgba(28, 25, 38, 1);
        outline-color: rgba(57, 53, 69, 1);
    }

    .on-boarding-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }

    .on-boarding-logout-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
    }

    .on-boarding-logout-link-icon {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }

    .on-boarding-logout-link-label {
        font-weight: 500;
        color: rgba(110, 106, 122, 1);
        font-size: 18px;
        line-height: 25px;
    }

    .on-boarding-back-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
    }

    .on-boarding-back-link-icon {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }

    .on-boarding-back-link-label {
        font-weight: 500;
        color: rgba(101, 66, 221, 1);
        font-size: 18px;
        line-height: 25px;
    }

    .on-boarding-skip-link {
        font-weight: 500;
        color: rgba(101, 66, 221, 1);
        font-size: 18px;
        line-height: 25px;
        cursor: pointer;
        margin-top: 16px;
    }

    .on-boarding-info-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 16px;

        &-label {
            color: rgba(101, 66, 221, 1);
            line-height: 26px;
            font-size: 18px;
            font-weight: 500;
        }

    }

    &-tabs {
        width: 100%;
        max-width: 720px;
        margin: 32px auto 0;
    }

    & .on-boarding-type-icon {
        margin: 37px auto 0;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    & .on-boarding-type-icon-image {
        width: 32px;
        height: 32px;
    }

    & .on-boarding-type-icon-label {
        font-size: 32px;
        line-height: 35px;
        font-weight: 600;
        margin-left: 6px;
    }


    .on-boarding-error {
        position: absolute;
        top: -20px;
        left: 5px;
        z-index: 1;
        color: #F03131;
    }

}

@media only screen and (max-width: $MAX_M_WIDTH) {

    .on-boarding {
        .on-boarding-logout-link-label, .on-boarding-back-link-label {
            font-size: 14px;
        }

        .on-boarding-line {
            margin-top: 50px;
        }

        .on-boarding-text {
            text-align: left;
        }

        .on-boarding-title {
            font-size: 26px;
            margin-top: 30px;
        }

        .on-boarding-button {
            width: 230px;
            height: 60px;
            font-size: 20px;
        }

        .on-boarding-profile-type-inner {
            width: 100%;
            height: 80px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 26px;
            box-sizing: border-box;
            border: 1px solid rgba(0, 98, 196, 1);
            border-radius: 16px;
            cursor: pointer;

            .on-boarding-profile-type-icon {
                width: 28px;
                height: 28px;
            }

            .on-boarding-profile-type-label {
                font-size: 20px;
            }

            .on-boarding-profile-type-check {
                width: 24px;
                height: 24px;
            }
        }

    }

}
