.alert-modal-icon {
  width: 65px;
  height: 65px;
  margin: auto;
  display: block;
}

.alert-modal-title {
  margin-top: 24px;
  display: block;
  text-align: center;
  color: rgba(28, 25, 38, 1);
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.alert-modal-text {
  margin: 24px auto 0;
  width: 290px;
  text-align: center;
  color: rgba(28, 25, 38, 1);
  font-size: 16px;
  line-height: 23px;
  display: block;
}
