@import '../../screen-sizes.scss';

.notifications {
    padding: 0 100px;
    box-sizing: border-box;
    padding-bottom: 75px;
}

.notifications-text {
    color: rgba(57, 53, 69, 1);
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    display: block;
}

.notifications-text span {
    font-weight: 500;
}

.notifications-area {
    margin-top: 40px;
    border-bottom: 1px solid rgba(205, 196, 235, 1);
    padding: 0 32px 24px 32px;
    box-sizing: border-box;
}

.notifications-area-label {
    color: rgba(28, 25, 38, 1);
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
}

.notification {
    border: 1px solid rgba(205, 196, 235, 1);
    margin-top: 16px;
    border-radius: 16px;
    padding: 12px 16px;
    box-sizing: border-box;
    height: 72px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.notification-profile-image {
    width: 48px;
    height: 48px;
    border-radius: 48px;
}

.notification-content {
    margin-left: 12px;
}

.notification-profile-name {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    display: block;
    text-decoration: none;
}

.notification-text {
    margin-top: 4px;
    display: block;
    color: rgba(57, 53, 69, 1);
    font-size: 16px;
    line-height: 23px;
}

.notifications-bottom {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    padding-top: 24px;
}

.notifications-upload-more-link {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    cursor: pointer;
}

.notifications-empty {
    padding-top: 64px;
    width: 100%;
    padding-bottom: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.notifications-empty-title {
    margin-top: 48px;
    display: block;
    text-align: center;
    color: rgba(28, 25, 38, 1);
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
}

.notifications-empty-text {
    margin: 16px auto 0;
    display: block;
    text-align: center;
    color: rgba(28, 25, 38, 1);
    font-size: 18px;
    line-height: 23px;
    max-width: 365px;
}

.notifications-loader {
    width: 100%;
    height: 300px;
    position: relative;
}

.notification-buttons {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-content: center;
}

.notification-button-decline {
    font-weight: 500;
    font-size: 18px;
    background: transparent;
    box-shadow: none;
    border: none;
    cursor: pointer;
}

.notification-button-allow {
    margin-left: 40px;
    border-radius: 44px;
    color: rgba(252, 251, 255, 1);
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    box-shadow: none;
    border: 1px solid #FFFFFF;
    height: 41px;
    width: 81px;
}

@media only screen and (max-width: $MAX_L_WIDTH) {
    .notifications {
        padding: 30px;
        padding-top: 0;
    }   
    
    .notifications-area {
        padding: 0 16px 20px 16px;
    }
}

@media only screen and (max-width: $MAX_M_WIDTH) {
    .notifications {
        padding: 30px;
        padding-top: 0;
    }   
    
    .notifications-area {
        padding: 0 0 20px 0;
    }
}