@import '../../screen-sizes.scss';

.sign-up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 70px;
    padding-bottom: 70px;
    background-color: #F4F1FF;
}

.sign-up-inner {
    background-color: rgba(252, 251, 255, 1);
    width: 622px;
    height: fit-content;
    border-radius: 24px;
    padding: 56px 72px 56px 72px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.sign-up-title {
    margin: 40px 0 16px;
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 0;
    text-align: center;
}

.sign-up-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: rgba(57, 53, 69);
    margin-top: 18px;
}

.sign-up-text-link {
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
}

.sign-up-back {
    margin-top: 40px;
    text-align: center;
    cursor: pointer;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    text-decoration: none;
    color: rgb(101, 66, 221);
}

.sign-up-resend {
    text-align: center;
    margin-top: 18px;
    font-size: 16px;
    line-height: 22px;
    color: rgb(28, 25, 38);
}

.sign-up-resend-link {
    cursor: pointer;
    text-decoration: underline;
    color: rgb(28, 25, 38);
}

.sign-up-switcher {
    margin-top: 40px;
    display: flex;
    justify-content: center;
}

.sign-up-field {
    margin-top: 24px;
}

.sign-up-button {
    margin-top: 40px;
}

.sign-up-sign-in-link {
    margin-top: 24px;
    display: block;
    text-align: center;
    font-size: 16px;
    line-height: 23px;
    color: rgba(85, 81, 101, 1);
}

.sign-up-agreement {
    margin-top: 32px;
}

.sign-up-sign-in-link-href {
    font-weight: 500;
    color: rgba(28, 25, 38, 1);
    text-decoration: none;
}

.sign-up-forgot-password {
    display: flex;
    flex-direction: row;
    margin-top: 11px;
}

.sign-up-forgot-password-link {
    margin-left: auto;
    color: rgba(28, 25, 38, 1);
    font-size: 16px;
    line-height: 23px;
    text-decoration: underline;
}

.sign-up-logo-with-icon {
    width: 239px;
    height: 64px;
    margin: auto;
}

.promo-page {
    width: 100%;
    background-image: url(./images/bg.png);
    background-size: cover;
    background-repeat: no-repeat;

    &-container {
        max-width: 1200px;
        margin: auto;
        flex-direction: row;
        display: flex;
    }

    &-left {
        width: 50%;
        padding-top: 70px;
        padding-bottom: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-right {
        width: 50%;
        padding-top: 70px;
        padding-bottom: 70px;
        display: flex;
        justify-content: center;
        align-items: center;

        &-image {
            width: 100%;
            max-width: 340px;
        }

    }

    &-form {
        padding: 48px 64px;
        box-sizing: border-box;
        width: 100%;
        border-radius: 24px;
        background-color: rgb(252, 251, 255);
        display: flex;
        flex-direction: column;
    }

}


@media only screen and (max-width: $MAX_S_WIDTH) {
    .sign-up {
        .sign-up-inner {
            margin: 0 20px;
            padding: 56px 32px;
        }
    }

}

@media only screen and (max-width: 1200px) {

    .promo-page-container {
        flex-direction: column;
    }

    .promo-page-left {
        width: 100%;
        padding: 42px 12px;
        box-sizing: border-box;
    }

    .promo-page-right {
        width: 100%;
        display: none;
    }

    .promo-page-form {
        padding: 36px 12px;
    }

}