@import "../../screen-sizes.scss";

.profile-page {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 32px;
  box-sizing: border-box;

  &-loader {
    width: 100%;
    height: 400px;
    position: relative;
  }

  &-left {
    width: 296px;
  }

  &-right {
    margin-left: auto;
    width: calc(100% - 296px - 32px);

    &-top {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &-edit-profile {
    margin-left: auto;
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgba(101, 66, 221, 1);
    text-decoration: none;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  &-name {
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 0;
    display: block;
    color: rgb(28, 25, 38);
  }

  &-location {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
    color: rgb(67, 63, 78);

    &-icon {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
  }

  &-info {
    &-area {
      margin-top: 24px;

      &-title {
        font-size: 24px;
        font-weight: 500;
        line-height: 31px;
        letter-spacing: 0;
        color: rgb(28, 25, 38);
      }

      &-element {
        margin-top: 16px;

        &:first-child {
          margin-top: 0;
        }

        &-text {
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0;
          margin-top: 16px;
          display: block;
          color: rgb(67, 63, 78);
        }

        &-title {
          font-size: 18px;
          font-weight: 600;
          line-height: 25px;
          letter-spacing: 0;
          color: rgb(57, 53, 69);
        }

        &-tips {
          display: flex;
          flex-wrap: wrap;
        }

        &-tip {
          margin-right: 12px;
          margin-top: 8px;
          height: 32px;
          display: flex;
          flex-direction: row;
          align-items: center;
          border-radius: 14px;
          padding: 4px 12px 4px 12px;
          background-color: rgb(242, 238, 255);
          font-size: 14px;
          color: rgb(28, 25, 38);
          letter-spacing: 0.25px;
        }
      }
    }
  }

  &-main-photo {
    width: 100%;
    border-radius: 34px;
    position: relative;

    &-image {
      width: 100%;
      border-radius: 34px;
    }

    &-empty {
      width: 100%;
      border-radius: 34px;
      background-color: rgba(234, 228, 255, 1);
      display: flex;
      position: relative;
      height: 296px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

  }

  &-photo-area {
    margin-top: 24px;

    &-label {
      font-size: 24px;
      color: rgba(28, 25, 38, 1);
      font-weight: 500;
      line-height: 32px;
    }

    &-text {
      margin-top: 12px;
      display: block;
      font-size: 14px;
      line-height: 20px;
      color: rgba(57, 53, 69, 1);
    }

    &-elements {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 4px;
    }

    &-element {
      width: calc(50% - 8px);
      margin-top: 8px;
      border-radius: 16px;
      position: relative;

      &-image {
        width: 100%;
        border-radius: 16px;
      }

    }
  }
}

.profile-page-photo-area-element:nth-child(2n + 1) {
  margin-right: 8px;
}

.profile-page-photo-area-element-private {
  cursor: pointer;
}

.profile-interaction {
  margin-top: 24px;
}

.profile-interaction-label {
  color: rgba(28, 25, 38, 1);
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  display: block;
  text-align: center;
}

.profile-interaction-icons {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: center;
}

.profile-interaction-icon {
  padding: 0;
  width: 48px;
  height: 48px;
  border-radius: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  box-sizing: border-box;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
}

.profile-interaction-chat-button {
  padding: 0;
  width: 135px;
  height: 48px;
  display: flex;
  border: 1px solid transparent;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 44px;
  color: #ffffff;
  box-shadow: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  cursor: pointer;
}

.profile-interactions-icons-right {
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
}

.profile-interaction-chat-button-icon {
  width: 24px;
  height: 24px;
}

.more-interaction {
  margin-top: 12px;
  position: relative;
  width: 100%;
  cursor: pointer;
}

.more-interaction-label {
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  display: block;
  padding-bottom: 29px;
}

.more-interaction-submenu {
  position: absolute;
  top: 29px;
  display: none;
  left: 0;
  width: 100%;
  z-index: 300;
  box-shadow: 2px 4px 20px 0 rgba(54, 21, 124, 0.15);
  background-color: rgba(252, 251, 255, 1);
  border-radius: 16px;
}

.more-interaction:hover .more-interaction-submenu {
  display: block;
}

.more-interaction-submenu-element {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  padding: 12px 24px;
  box-sizing: border-box;
}

.more-interaction-submenu-element-label {
  color: rgba(28, 25, 38, 1);
  font-size: 18px;
  line-height: 24px;
}

.more-interaction-submenu-element-icon {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  color: rgba(28, 25, 38, 1);
}

@media only screen and (max-width: $MAX_M_WIDTH) {
  .profile-page {
    flex-direction: column;
    padding-top: 0;

    &-left {
      width: unset;
    }

    &-right {
      width: unset;
      margin-left: unset;
      margin-top: 12px;

      &-top {
        justify-content: center;
      }
    }

    &-location {
      justify-content: center;
    }

    &-edit-profile {
      margin-top: 24px;
      justify-content: center;
    }

    &-main-photo {
      max-width: 300px;
      display: block;
      margin: 0 auto;
    }

    &-main-photo-empty {
      max-width: 300px;
      display: flex;
      margin: 0 auto;
    }

    &-photo-area-label {
      display: none;
    }
  }
}

.profile-page-block-label {
  margin-left: auto;
  color: rgba(240, 49, 49, 1);
  font-size: 18px;
  line-height: 25px;
  font-weight: 500;
}

.profile-page-block-area {
  margin-top: 60px;
}

.profile-page-block-area-text {
  display: block;
  color: rgba(28, 25, 38, 1);
  font-size: 18px;
  line-height: 24px;
}

.profile-page-block-area-bottom {
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-page-block-area-unblock-button {
  width: 132px;
  height: 56px;
  box-shadow: none;
  border-radius: 44px;
  border: 1px solid #FFFFFF;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  background-color: transparent;
}

.profile-page-block-area-view-button {
  margin-left: auto;
  width: 132px;
  height: 56px;
  box-shadow: none;
  border-radius: 44px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid #FFFFFF;
  font-size: 18px;
  color: #FFFFFF;
}

.profile-page-photo-area-request-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  color: rgba(101, 66, 221, 1);
  font-size: 14px;
  line-height: 20px;
}

.profile-page-photo-area-share {
  margin-top: 12px;
  width: 100%;

  & .switcher-label {
    color: rgba(28, 25, 38, 1);
    font-size: 16px;
    line-height: 23px;
  }

  & .switcher-inner {
    margin-left: auto;
  }

}
