@import '../../screen-sizes.scss';

.chat-page {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    height: 100%;
    max-height: calc(100vh - 80px - 32px - 32px);
    min-height: calc(100vh - 80px - 32px - 32px);
}

.chat-page-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.chat-page-right {
    margin-left: auto;
    min-width: 267px;
    background-color: rgba(252, 251, 255, 1);
    border-radius: 12px;
    overflow-y: auto;
    position: relative;
}

.chat-page-top {
    background-color: rgba(252, 251, 255, 1);
    width: 100%;
    border-radius: 12px;
    padding: 8px 16px;
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    align-items: center;
}

.chat-page-top-photo {
    width: 40px;
    height: 40px;
    border-radius: 40px;
}

.chat-page-top-profile {
    margin-left: 16px;
}

.chat-page-top-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: rgba(28, 25, 38, 1);
    cursor: pointer;
}

.chat-page-top-profile-name {
    display: block;
    color: rgba(28, 25, 38, 1);
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
}

.chat-page-top-profile-status {
    display: block;
    margin-top: 4px;
    font-size: 14px;
    line-height: 19px;
    color: rgba(85, 81, 101, 1);
}

.chat-page-top-close-icon {
    margin-left: auto;
    width: 35px;
    height: 35px;
    cursor: pointer;
}

.chat-window {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    position: relative;
}

.chat-window-wrapper {
    height: 100%;
    padding: 33px 0;
    box-sizing: border-box;
}

.chat-window-send-area {
    height: 56px;
    width: 100%;
    border-radius: 12px;
    padding: 8px 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(252, 251, 255, 1);
}

.chat-window-send-area-button {
    margin-left: auto;
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: block;
    box-shadow: none;
    border: none;
    background-color: transparent;
}

.chat-window-send-area-button:disabled {
    opacity: .7;
}

.chat-window-send-area-text-input {
    font-size: 16px;
    line-height: 23px;
    height: 56px;
    border: none;
    outline: none;
    width: 100%;
    background-color: transparent;
}

.chat-window-send-area-text-input::placeholder {
    color: rgba(157, 152, 170, 1);
}

.chat-message {
    width: 100%;
    margin-top: 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
}

.chat-message:first-child {
    margin-top: 0;
}

.chat-message-1 {
    padding-right: 100px;
}

.chat-message-2 {
    padding-left: 100px;
}

.chat-message-1 .chat-message-inner {
    background-color: rgba(252, 251, 255, 1);
}

.chat-message-2 .chat-message-inner {
    margin-left: auto;
}

.chat-message-inner {
    padding: 8px 16px;
    box-sizing: border-box;
    border-radius: 12px;
    max-width: 100%;
}

.chat-message-text {
    display: block;
    color: rgba(57, 53, 69, 1);
    font-size: 16px;
    line-height: 23px;
    word-wrap: break-word;
}

.chat-message-2 .chat-message-text {
    text-align: right;
    color: #ffffff;
}

.chat-message-bottom {
    margin-top: 4px;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.chat-message-time {
    font-size: 12px;
    line-height: 16px;
    color: rgba(1, 22, 39, 1);
    margin-left: auto;
}

.chat-message-2 .chat-message-time {
    color: #ffffff;
}

@media only screen and (max-width: $MAX_M_WIDTH) {

    .chat-page {
        max-height: calc(100vh - 70px);

        .chat-window {
            height: 100%;
        }

        .chat-window-wrapper {
            padding: 33px 10px;
        }
    }

}
