@import '../../screen-sizes.scss';

.users-empty {
    padding-top: 64px;
    width: 100%;
}

.users-loader {
    width: 100%;
    position: relative;
    height: 500px;
}

.users-empty-title {
    margin-top: 48px;
    display: block;
    text-align: center;
    color: rgba(28, 25, 38, 1);
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
}

.users-empty-text {
    margin: 16px auto 0;
    display: block;
    text-align: center;
    color: rgba(28, 25, 38, 1);
    font-size: 18px;
    line-height: 23px;
    max-width: 365px;
}

.users-empty-icon {
    width: 279px;
    height: 262px;
    margin: auto;
    display: block;
}

.users {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    row-gap: 16px;
    justify-content: space-between;
    margin-top: 24px;
    width: 100%;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}

.users-element {
    width: calc(33.33% - 16px);
}

.user {
    width: 100%;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.user-photo-area {
    padding-top: 100%;
    height: auto;
    position: relative;
}

.user-photo-area-image {
    width: 100%;
    left: 0;
    background-color: rgba(234, 228, 255, 1);
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    border-radius: 20px 20px 0 0;
}

.user-photo-area-image-label {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 2;
    padding: 2px 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border-radius: 16px;
    background-color: rgba(255, 194, 78, 1);
    color: rgba(85, 81, 101, 1);
}

.img-bottom-icons {
    position: absolute;
    bottom: 16px;
    display: flex;
    width: 100%;
    padding: 0 16px;
    gap: 10px;

    .user-location-icon {}

    .musl-icon {
        background-color: #fff;
        height: 28px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;

        img {
            width: 44px;
        }
    }
}

.user-bottom {
    background-color: rgba(252, 251, 255, 1);
    padding: 12px 24px;
    box-sizing: border-box;
    border-radius: 0 0 20px 20px;
}

.user-name {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}

.user-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 14px;
    padding: 2px 8px;
}

.user-icon-label {
    font-size: 14px;
    line-height: 24px;
    color: rgba(57, 53, 69, 1);
}

.user-icon-image {
    width: 16px;
    height: 16px;
    margin-right: 4px;
}

.user-icons {
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.users-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.users-top-filter-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
    background-color: rgba(252, 251, 255, 1);
    padding: 18px 16px;
    box-sizing: border-box;
    cursor: pointer;
    height: 56px;
    width: 160px;
}

.users-top-filter-icon-label {
    font-weight: 500;
    line-height: 28px;
    font-size: 20px;
}

.users-top-filter-icon-image {
    width: 20px;
    height: 20px;
    margin-right: 12px;
}

.users-top-filter-icon-circle {
    margin-left: 12px;
    width: 22px;
    height: 22px;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.users-top-filter-icon-circle-num {
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
}

.users-top-search {
    background-color: rgba(252, 251, 255, 1);
    margin-left: 16px;
    width: calc(100% - 16px - 160px);
    border-radius: 20px;
}

.user-photo-visible-icon {
    position: absolute;
    right: 16px;
    z-index: 2;
    top: 16px;
    width: 84px;
    height: 28px;
    border-radius: 14px;
    padding: 6px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;

    & svg {
        margin-right: 4px;
    }

}

.user-location-lf-icons {
    position: absolute;
    bottom: 16px;
    right: 16px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.user-location-lf-icon {
    width: 28px;
    height: 28px;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    color: #FFFFFF;
}

@media only screen and (max-width: $MAX_L_WIDTH) {

    .users-wrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 20px 0;
    }

    .users-element {
        width: calc(50% - 16px);
    }

}

@media only screen and (max-width: $MAX_M_WIDTH) {

    .container {
        width: 100%;
    }

    .users-wrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 20px 0;
    }

    .users-top {

        .users-top-filter-icon-label {
            display: none;
        }

        .users-top-filter-icon {
            position: relative;


            .users-top-filter-icon-image {
                margin-right: 0px;
            }

            .users-top-filter-icon-circle {
                right: 0;
                top: 0;

                position: absolute;

            }

            width: fit-content;
        }

        .users-top-search {
            width: 100%;

        }
    }

}


@media only screen and (max-width: $MAX_S_WIDTH) {


    .container {
        width: 100%;
    }

    .users {
        justify-content: center;

        .users-element {
            max-width: 340px;
            width: 100%;
        }
    }

}