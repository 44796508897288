.small-profile-image {
    width: 100%;
    border-radius: 12px 12px 0 0;
}

.small-profile-info {
    padding: 16px 24px;
    box-sizing: border-box;
}

.small-profile-interaction .profile-interaction {
    margin-top: 0;
}

.small-profile-interaction .profile-interaction-icon {
    width: 44px;
    height: 44px;
    margin-left: 16px;
}


.small-profile-interaction .profile-interaction-icon:first-child {
    margin-left: 0;
}

.small-profile-interaction .more-interaction-label {
    font-size: 16px;
    line-height: 23px;
}

.small-profile-name {
    display: block;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
}

.small-profile-location-icon {
    margin-top: 8px;
    display: flex;
}

.small-profile-area-element-text {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    margin-top: 16px;
    display: block;
    color: rgba(57, 53, 69, 1);
}

.small-profile-area-element-tips {
    display: flex;
    flex-wrap: wrap;
}

.small-profile-area-element {
    margin-top: 16px;
}

.small-profile-area-element-tip {
    margin-right: 8px;
    margin-top: 8px;
    height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 14px;
    padding: 2px 8px;
    background-color: rgb(242, 238, 255);
    font-size: 14px;
    color: rgb(28, 25, 38);
    letter-spacing: 0.25px;
}

.small-profile-area-element-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0;
    color: rgba(57, 53, 69, 1);
}
