.u-radio-switcher {
    display: flex;
    flex-direction: row;
}

.u-radio-switcher-element {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 52px;
}

.u-radio-switcher-element:first-child {
    margin-left: 0;
}

.u-radio-switcher-element-label {
    margin-left: 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    user-select: none;
}

.u-radio-switcher-element-circle {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 2px solid rgba(110, 106, 122, 1);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.u-radio-switcher-element-circle.active {
    border-color: rgba(101, 66, 221, 1);
}

.u-radio-switcher-element-circle-inner {
    background-color: rgba(101, 66, 221, 1);
    width: 10px;
    height: 10px;
    border-radius: 10px;
}
