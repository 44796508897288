.u-button {
  width: 100%;
  box-sizing: border-box;
  box-shadow: none;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    90deg,
    #0162c4 0%,
    #3456d4 11.87%,
    #4c4eda 24.37%,
    #5c4ad8 36.87%,
    #6f46d6 50.52%,
    #7444d5 66.67%,
    #9344c7 83.85%,
    #a44bb9 100%
  );
  color: rgba(252, 251, 255, 1);
  font-weight: 500;
  font-size: 18px;
  border-radius: 44px;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.35;
  }

  &-loader-icon {
    margin-right: 10px;
  }
}
