.date-field {
    width: 100%;
    position: relative;
}

.date-field-submenu {
    margin-top: 10px;
    width: 100%;
    border-radius: 20px;
    background-color: #ffffff;
    border: 1px solid rgba(205, 196, 235, 1);
    padding-bottom: 23px;
}

.date-field-icon {
    margin-left: auto;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
}

.date-field-input {
    border: 1px solid rgba(157, 152, 170, 1);
    width: 100%;
    height: 56px;
    border-radius: 12px;
    padding-left: 16px;
    padding-right: 56px;
    box-sizing: border-box;
    font-size: 18px;
    color: rgba(28, 25, 38, 1);
    outline-color: rgba(57, 53, 69, 1);
}

.react-calendar {
    background: transparent!important;
    border: 0!important;
    color: #000!important;
}

.react-calendar button {
    border: 0!important;
    box-shadow: none!important;
    border-radius: 16px!important;
    transition: none!important;

    &:hover {
        opacity: 1;
        color: rgba(57, 53, 69, 1);
        border-radius: 16px!important;
        background-color: rgba(234, 228, 255, 1)
    }

}

.react-calendar__tile--active {
    background: rgba(101, 66, 221, 1);
    font-weight: bold!important;
    color: #FFFFFF!important;
}

.react-calendar__navigation button {
    color: rgba(57, 53, 69, 1);
    padding: 0;

    &:hover {
        opacity: 1;
        color: rgba(57, 53, 69, 1);
        border-radius: 16px!important;
        background-color: rgba(234, 228, 255, 1)
    }

}

.react-calendar__month-view__days__day {
    color: rgba(57, 53, 69, 1);
    font-size: 14px;
}

.react-calendar__tile:disabled {
    background-color: transparent!important;
}
