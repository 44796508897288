.favorites-users-empty {
    padding-top: 64px;
    width: 100%;
}

.favorites-users-empty-title {
    margin-top: 48px;
    display: block;
    text-align: center;
    color: rgba(28, 25, 38, 1);
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
}

.favorites-users-empty-text {
    margin: 16px auto 0;
    display: block;
    text-align: center;
    color: rgba(28, 25, 38, 1);
    font-size: 18px;
    line-height: 23px;
    max-width: 365px;
}

.favorites-users-empty-icon {
    width: 299px;
    height: 266px;
    margin: auto;
    display: block;
}

.favorites-users-link {
    margin-top: 24px;
    display: block;
    text-align: center;
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
}
