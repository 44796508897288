.visibility-modal {

}

.visibility-modal-icon {
  width: 65px;
  height: 65px;
  margin: auto;
}

.visibility-modal-title {
  display: block;
  margin-top: 24px;
  text-align: center;
  color: rgba(28, 25, 38, 1);
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
}

.visibility-modal-elements {
  margin-top: 8px;
}

.visibility-modal-element {
  display: flex;
  flex-direction: row;
  max-width: 366px;
  margin: 16px auto 0;
  align-items: center;
}

.visibility-modal-element-label {
  margin-left: 4px;
  color: rgba(28, 25, 38, 1);
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}

.visibility-modal-element-icon {
  width: 20px;
  margin-left: 10px;
  height: 20px;
}

.visibility-modal-element-avatar {
  width: 44px;
  height: 44px;
  border-radius: 100%;
}

.visibility-modal-element-switcher {
  margin-left: auto;
}
