@import '../../screen-sizes.scss';

.messages {
    margin-top: 24px;
}

.messages-loader {
    width: 100%;
    height: 300px;
    position: relative;
}

.messages-empty {
    padding-top: 64px;
    width: 100%;
}

.messages-empty-title {
    margin-top: 48px;
    display: block;
    text-align: center;
    color: rgba(28, 25, 38, 1);
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
}

.messages-empty-text {
    margin: 16px auto 0;
    display: block;
    text-align: center;
    color: rgba(28, 25, 38, 1);
    font-size: 18px;
    line-height: 23px;
    max-width: 365px;
}

.messages-empty-icon {
    width: 279px;
    height: 262px;
    margin: auto;
    display: block;

}

@media only screen and (max-width: $MAX_M_WIDTH) {

    .messages-container {
        padding: 20px;
    }

}


