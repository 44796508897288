.switcher-pop-up {
  &-overlay {
    height: 0;
    width: 100%;
    position: fixed;
    z-index: 12;
    top: 0;
    left: 0;
    background-color: transparent;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: end;

    &.active {
      height: 100%;
      background-color: #1111115c;
      transition: background-color 0.3s;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 24px 16px 40px 16px;
    border-radius: 32px 32px 0 0;
  }

  &-top {
    display: flex;
    justify-content: space-between;
  }

  &-label {
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0;
    display: block;
    font-weight: 500;
  }

  &-close {
    background-color: transparent;
    border: none;
  }

  &-new-profile-button {
    padding: 16px 24px 16px 24px;
    color: white;
    border-radius: 44px;
    text-decoration: none;
    text-align: center;
    font-size: 18px;
    line-height: 25px;
    margin-top: 42px;
  }
}
