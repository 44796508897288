.modal-fade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(0,0,0,.2);
}

.modal-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal {
  border-radius: 16px;
  padding: 32px;
  box-sizing: border-box;
  bottom: 0;
  background-color: #ffffff;
  z-index: 300;
  min-width: 300px;
  position: relative;
}

.modal-close {
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 24px;
}

.modal-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
}

.modal-bottom-centered {
  justify-content: center;
}

.modal-bottom-centered .modal-submit-button {
  margin-left: 0;
}

.modal-cancel-button {
  height: 57px;
  width: 120px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  border-radius: 44px;
  border: 1px solid rgba(101, 66, 221, 1);
  color: rgba(101, 66, 221, 1);
  font-weight: 500;
  font-size: 18px;
}

.modal-submit-button {
  height: 57px;
  width: 120px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  cursor: pointer;
  background-color: rgba(101, 66, 221, 1);
  border-radius: 44px;
  border: 1px solid rgba(101, 66, 221, 1);
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  margin-left: auto;
}

.modal-submit-button:disabled {
  opacity: .35;
}
