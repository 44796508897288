.switcher {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.switcher-label {
    color: rgba(57, 53, 69, 1);
    font-size: 20px;
    line-height: 22px;
}

.switcher-inner {
    position: relative;
    margin-left: auto;
    width: 36px;
    height: 14px;
    border-radius: 8px;
    background-color: rgba(157, 152, 170, 1);
    cursor: pointer;
}

.switcher-inner-circle {
    width: 20px;
    height: 20px;
    top: -3px;
    border-radius: 20px;
    background-color: #ffffff;
    position: absolute;
    left: 0;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
}

.switcher-inner.checked .switcher-inner-circle {
    left: 16px;
}
