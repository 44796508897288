$friendBackgroundColor: rgba(235, 246, 255, 1);
$flirtBackgroundColor: rgba(244, 241, 255, 1);
$funBackgroundColor: rgba(255, 242, 255, 1);

$friendBackgroundColorOpacity: rgba(220, 239, 255, 1);
$flirtBackgroundColorOpacity: rgba(234, 228, 255, 1);
$funBackgroundColorOpacity: rgba(255, 236, 255, 1);

$friendColor: rgba(0, 98, 196, 1);
$flirtColor: rgb(101, 66, 221);
$funColor: rgba(167, 76, 184, 1);

.page-with-menu.friend,
.page-without-menu.friend {
  background-color: $friendBackgroundColor;

  & .profile-button {
    background-color: $friendColor;
    border-color: $friendColor;
  }

  & .profile-border-color {
    border-color: $friendColor;
  }

  & .profile-text-color {
    color: $friendColor;
  }

  & .profile-background-color-opacity {
    background-color: rgba(242, 238, 255, 0.6);
  }

  & .profile-background-color {
    background-color: $friendColor;
  }

  & .left-menu-element.active {
    background-color: $friendBackgroundColorOpacity;

    & .left-menu-element-label {
      color: $friendColor;
    }
  }

  & .left-menu-element:hover {
    background-color: $friendBackgroundColorOpacity;
  }

  & .profile-page-info-area-element-tip {
    background-color: $friendBackgroundColorOpacity;
  }

  & .profile-page-edit-profile {
    color: $friendColor;
  }

  & .tab.active {
    background-color: $friendBackgroundColorOpacity;
  }

  & .edit-profile-tab-tips-element.active {
    background-color: $friendColor;
  }

  & .photos-input-element {
    background-color: $friendBackgroundColorOpacity;
  }

  & .edit-profile-submit-button {
    background-color: $friendColor;
    border-color: $friendColor;
  }

  & .edit-profile-cancel-link {
    color: $friendColor;
  }

  & .left-switcher-picker.opened {
    background-color: $friendBackgroundColorOpacity;
  }

  & .account-management-change-password-link {
    color: $friendColor;
  }

  & .photos-input-element svg {
    color: $friendColor;
  }

  & .left-switcher-profile-checkmark {
    color: $friendColor;
  }

  & .stats-switcher-element.active {
    background-color: $friendBackgroundColorOpacity;
    color: $friendColor;
  }

  & .stats-element-icon {
    background-color: $friendBackgroundColorOpacity;
    color: $friendColor;
  }

  .switcher-inner.checked .switcher-inner-circle {
    background-color: $friendColor;
  }

  .switcher-inner.checked {
    background-color: $friendBackgroundColor;
  }

  .loader::before {
    border-color: $friendColor;
  }

  .loader::after {
    border-color: $friendColor;
  }

  & .profile-interaction-icon.active {
    background-color: rgba(220, 239, 255, 1);
    border-color: rgba(220, 239, 255, 1);
  }
}

.page-with-menu.flirt,
.page-without-menu.flirt {
  background-color: $flirtBackgroundColor;

  & .profile-button {
    background-color: $flirtColor;
    border-color: $flirtColor;
  }

  & .profile-background-color-opacity {
    background-color: rgba(242, 238, 255, 0.6);
  }

  & .profile-border-color {
    border-color: $flirtColor;
  }

  & .profile-text-color {
    color: $flirtColor;
  }

  & .left-menu-element.active {
    background-color: $flirtBackgroundColorOpacity;

    & .left-menu-element-label {
      color: $flirtColor;
    }
  }

  & .left-menu-element:hover {
    background-color: $flirtBackgroundColorOpacity;
  }

  & .profile-page-info-area-element-tip {
    background-color: $flirtBackgroundColorOpacity;
  }

  & .profile-page-edit-profile {
    color: $friendColor;
  }

  & .tab.active {
    background-color: $flirtBackgroundColorOpacity;
  }

  & .edit-profile-tab-tips-element.active {
    background-color: $flirtColor;
  }

  & .photos-input-element {
    background-color: $flirtBackgroundColorOpacity;
  }

  & .edit-profile-submit-button {
    background-color: $flirtColor;
    border-color: $flirtColor;
  }

  & .edit-profile-cancel-link {
    color: $flirtColor;
  }

  & .left-switcher-picker.opened {
    background-color: $flirtBackgroundColorOpacity;
  }

  & .profile-background-color {
    background-color: $flirtColor;
  }

  & .account-management-change-password-link {
    color: $flirtColor;
  }

  & .photos-input-element svg {
    color: $flirtColor;
  }

  & .left-switcher-profile-checkmark {
    color: $flirtColor;
  }

  .switcher-inner.checked .switcher-inner-circle {
    background-color: $flirtColor;
  }

  & .stats-switcher-element.active {
    background-color: $flirtBackgroundColorOpacity;
    color: $flirtColor;
  }

  .switcher-inner.checked {
    background-color: $flirtBackgroundColor;
  }

  & .profile-interaction-icon.active {
    background-color: rgba(234, 228, 255, 1);
    border-color: rgba(234, 228, 255, 1);
  }

}

.page-with-menu.fun,
.page-without-menu.fun {
  background-color: $funBackgroundColor;

  & .profile-button {
    background-color: $funColor;
    border-color: $funColor;
  }

  & .profile-border-color {
    border-color: $funColor;
  }

  & .profile-text-color {
    color: $funColor;
  }

  & .profile-background-color {
    background-color: $funColor;
  }

  & .left-menu-element.active {
    background-color: $funBackgroundColorOpacity;

    & .left-menu-element-label {
      color: $funColor;
    }
  }

  & .left-menu-element:hover {
    background-color: $funBackgroundColorOpacity;
  }

  & .profile-page-info-area-element-tip {
    background-color: $funBackgroundColorOpacity;
  }

  & .profile-page-edit-profile {
    color: $funColor;
  }

  & .tab.active {
    background-color: $funBackgroundColorOpacity;
  }

  & .edit-profile-tab-tips-element.active {
    background-color: $funColor;
  }

  & .photos-input-element {
    background-color: $funBackgroundColorOpacity;
  }

  & .edit-profile-submit-button {
    background-color: $funColor;
    border-color: $funColor;
  }

  & .edit-profile-cancel-link {
    color: $funColor;
  }

  & .left-switcher-picker.opened {
    background-color: $funBackgroundColorOpacity;
  }

  & .account-management-change-password-link {
    color: $funColor;
  }

  & .photos-input-element svg {
    color: $funColor;
  }

  & .profile-background-color-opacity {
    background-color: rgba(242, 238, 255, 0.6);
  }

  & .stats-switcher-element.active {
    background-color: $funBackgroundColorOpacity;
    color: $funColor;
  }

  & .left-switcher-profile-checkmark {
    color: $funColor;
  }

  .switcher-inner.checked .switcher-inner-circle {
    background-color: $funColor;
  }

  .switcher-inner.checked {
    background-color: $funBackgroundColor;
  }

  & .profile-interaction-icon.active {
    background-color: rgba(255, 236, 255, 1);
    border-color: rgba(255, 236, 255, 1);
  }

}