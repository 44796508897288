@import '../../screen-sizes.scss';

.edit-profile {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 32px 25px;
    box-sizing: border-box;
    gap: 35px;
}

.edit-profile-left {
    max-width: 296px;
}

.edit-profile-right {
    max-width: calc(100% - 296px - 32px);
    width: 100%;
    padding-bottom: 80px;
}

.edit-profile-tab-input {
    width: 100%;
    border-radius: 12px;
    background-color: rgba(252, 251, 255, 1);
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    height: 50px;
    border: 1px solid rgba(252, 251, 255, 1);
    font-size: 18px;
    color: rgba(28, 25, 38, 1);
    line-height: 23px;
}

.edit-profile-tab-textarea {

    &-field {
        width: 100%;
        border-radius: 12px;
        background-color: rgba(252, 251, 255, 1);
        padding: 16px;
        box-sizing: border-box;
        border: 1px solid rgba(252, 251, 255, 1);
        font-size: 18px;
        color: rgba(28, 25, 38, 1);
        line-height: 23px;
        height: 120px;
        resize: none;
        font-family: 'Exo', sans-serif;
    }

    &-limit {
        margin-left: auto;
        color: rgba(110, 106, 122, 1);
        font-size: 14px;
        line-height: 16px;
    }

    &-bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 4px;
    }

}

.edit-profile-tab-content-child {
    margin-top: 24px;
}

.edit-profile-tab-content-child:first-child {
    margin-top: 0;
}

.edit-profile-tab-content-child-label {
    display: block;
    font-size: 18px;
    line-height: 25px;
    font-weight: 600;
    color: rgba(28, 25, 38, 1);
    margin-bottom: 16px;
}

.edit-profile-tab-content-child-sub-label {
    display: block;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: rgba(28, 25, 38, 1);
    margin-bottom: 16px;
}

.edit-profile-details {
    display: flex;
    flex-direction: row;
    gap: 24px;

    &-col {
        width: calc(50% - 12px)
    }

    &-label {
        display: block;
        font-size: 18px;
        line-height: 25px;
        font-weight: 600;
        color: rgb(28, 25, 38);
        margin-bottom: 16px;
    }

    &-input {
        position: relative;
        width: 100%;
        border: 1px solid rgb(157, 152, 170);
        height: 56px;
        border-radius: 12px;
        padding-left: 16px;
        padding-right: 56px;
        box-sizing: border-box;
        font-size: 18px;
        color: rgb(28, 25, 38);
        outline-color: rgb(57, 53, 69);
        background-color: #FFFFFF;
        display: flex;
        flex-direction: row;
        align-items: center;

        & input {
            // width: calc(100% - 60px);
            min-width: 60px;
            border: none;
            outline: none;
            height: 30px;
            font-size: 18px;
            color: rgb(28, 25, 38);
        }

        &-label {
            position: absolute;
            right: 16px;
            top: 0;
            height: 56px;
            line-height: 56px;
            color: rgba(110, 106, 122, 1);
            font-size: 18px;
        }

    }

}

.edit-profile-tab-tips {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.edit-profile-tab-tips-element {
    margin-bottom: 8px;
    margin-right: 8px;
    padding: 6px 12px;
    border-radius: 16px;
    height: 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(252, 251, 255, 1);
    font-size: 14px;
    color: rgba(85, 81, 101, 1);
    cursor: pointer;
}

.edit-profile-tab-tips-element.active {
    background-color: rgba(101, 66, 221, 1);
    color: #ffffff;
}

.edit-profile-bottom {
    width: 100%;
    border-top: 1px solid rgba(205, 196, 235, 1);
    padding: 24px 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.edit-profile-cancel-link {
    color: rgba(101, 66, 221, 1);
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
}

.edit-profile-submit-button {
    margin-left: auto;
    width: 120px;
    height: 57px;
    border-radius: 44px;
    padding: 16px 32px;
    box-sizing: border-box;
    background-color: rgba(101, 66, 221, 1);
    color: rgba(252, 251, 255, 1);
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    border: 1px solid rgba(101, 66, 221, 1);
    box-shadow: none;
    cursor: pointer;

    &:disabled {
        opacity: 0.35;
    }
}

.edit-profile-photo-area {
    margin-top: 24px;
}

.edit-profile-photo-area-label {
    font-size: 24px;
    color: rgba(28, 25, 38, 1);
    font-weight: 500;
    line-height: 32px;
}

.edit-profile-photo-area-text {
    margin-top: 12px;
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: rgba(57, 53, 69, 1);
}

.edit-profile-photo-area-elements {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 12px;
}

@media only screen and (max-width: $MAX_L_WIDTH) {
    .edit-profile {
        flex-direction: column;

        .edit-profile-left {
            max-width: unset;
            width: 100%;
            img {
                max-width: 300px;
                display: flex;
                margin: auto;
            }
        }

        .edit-profile-right {
            max-width: unset;
            width: 100%;

        }
    }

}

.edit-profile-page-avatar {
    position: relative;
}

.edit-profile-page-avatar-img {
    width: 100%;
    border-radius: 34px;
    height: 100%;
}

.edit-profile-page-avatar:hover .photos-input-element-fade {
    display: flex;
}

@media only screen and (max-width: $MAX_S_WIDTH) {
    .edit-profile-photo-area-elements {
        justify-content: center;
    }
}
