@import "../../screen-sizes.scss";

.left-switcher {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 16px;

  &-picker {
    border-radius: 16px;
    padding-top: 12px;

    &-top {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 100%;
      cursor: pointer;
    }

    &-content {
      padding-left: 8px;
      padding-right: 8px;
      padding-bottom: 12px;
    }

    &-icon {
      width: 24px;
      height: 24px;
    }

    &-label {
      margin-left: 4px;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0;
    }

    &-arrow {
      margin-left: 4px;
      width: 24px;
      height: 24px;
      transform: rotate(180deg);
    }

    &.opened {
      background-color: rgba(242, 238, 255, 1);

      .left-switcher-picker-arrow {
        transform: none;
      }
    }
  }

  &-button {
    margin-top: 12px;
    height: 57px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 44px;
    width: 100%;
    box-shadow: none;
    border: none;
    text-decoration: none;
    background-color: rgb(101, 66, 221);
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0;
    box-sizing: border-box;

    &-icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  &-profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 12px;
    cursor: pointer;

    &-image {
      border: 1px solid rgba(252, 251, 255, 1);
      border-radius: 32px;
      width: 32px;
      height: 32px;
    }

    &-content {
      margin-left: 8px;
    }

    &-name {
      color: rgba(28, 25, 38, 1);
      font-size: 14px;
      line-height: 20px;
      display: block;
    }

    &-type {
      display: flex;
      flex-direction: row;
      align-content: center;

      &-icon {
        width: 20px;
        height: 20px;
      }

      &-label {
        margin-left: 4px;
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
    }

    &-checkmark {
      margin-left: auto;
      width: 24px;
      height: 24px;
    }
  }

  &-picker-create-button {
    width: 100%;
    border-radius: 44px;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(101, 66, 221, 1);
    background-color: rgba(252, 251, 255, 1);
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    text-decoration: none;
    margin-top: 12px;

    &-friend {
      border-color: rgba(0, 98, 196, 1);
      color: rgba(0, 98, 196, 1);
    }

    &-flirt {
      border-color: rgb(101, 66, 221);
      color: rgb(101, 66, 221);
    }

    &-fun {
      border-color: rgba(167, 76, 184, 1);
      color: rgba(167, 76, 184, 1);
    }

    &-icon {
      width: 16px;
      height: 16px;
      margin-right: 4px;
      margin-left: 4px;
    }

    & .uppercase {
      margin-left: 4px;
    }
  }

  &-current-profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    box-sizing: border-box;
    width: 100%;
    border-radius: 44px;
    margin-top: 12px;
    text-decoration: none;

    &-name {
      margin-left: 8px;
      color: rgba(252, 251, 255, 1);
      font-weight: 600;
      font-size: 20px;
      line-height: 22px;
    }

    &-photo {
      box-sizing: border-box;
      border: 1px solid #ffffff;
      border-radius: 40px;
      width: 40px;
      height: 40px;
    }
  }
}

@media only screen and (max-width: $MAX_M_WIDTH) {
  .left-switcher {
    flex-direction: column-reverse;
    padding: 0;
    margin-bottom: 12px;

    &-button {
      padding-left: 0;
      justify-content: start;
    }
  }
}
