.u-agreement {
    display: flex;
    flex-direction: row;
    padding-right: 32px;
    box-sizing: border-box;
    padding-left: 3px;
}

.u-agreement-square {
    width: 18px;
    height: 18px;
    border-radius: 6px;
    border: 1px solid rgba(110, 106, 122, 1);
    position: relative;
    top: 4px;
    cursor: pointer;
}

.u-agreement-square-checkmark {
    width: 18px;
    height: 18px;
    display: block;
    border-radius: 4px;
}

.u-agreement-text {
    color: rgba(85, 81, 101, 1);
    font-size: 16px;
    line-height: 23px;
    display: block;
    margin-left: auto;
    width: calc(100% - 35px);
}

.u-agreement-text-link {
    text-decoration: none;
    font-weight: 500;
    color: rgba(28, 25, 38, 1);
}
