.chat {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    border-radius: 16px;
    padding: 12px 16px;
    box-sizing: border-box;
    background-color: rgba(252, 251, 255, 1);
    margin-top: 12px;
    cursor: pointer;
    text-decoration: none;
    position: relative;
}

.chat:first-child {
    margin-top: 0;
}

.chat-profile-photo {
    width: 50px;
    height: 50px;
    border-radius: 50px;
}

.chat-right {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    height: 25px;
    align-items: center;
    position: absolute;
    right: 16px;
    top: 12px;
}

.chat-time {
    display: block;
    color: rgba(110, 106, 122, 1);
    font-size: 14px;
    line-height: 20px;
}

.chat-profile {
    margin-left: 12px;
    width: calc(100% - 87px)
}

.chat-profile-top {
    display: flex;
    flex-direction: row;
    height: 25px;
    align-items: center;
}

.chat-profile-circle {
    margin-left: 8px;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    display: block;
}

.chat-profile-name {
    display: block;
    color: rgba(57, 53, 69, 1);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
}

.chat-profile-text {
    display: block;
    color: rgba(110, 106, 122, 1);
    font-size: 16px;
    line-height: 23px;
    margin-top: 4px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.chat.small {
    background-color: transparent;
    padding: 8px 16px;
    margin-top: 8px;
}

.chat.small .chat-profile-photo {
    width: 48px;
    height: 48px;
    border-radius: 48px;
}

.chat.small .chat-profile-name {
    font-size: 16px;
    line-height: 23px;
}

.chat.small .chat-profile-text {
    font-size: 14px;
    line-height: 20px;
}

.chat.small .chat-time {
    font-size: 12px;
}
