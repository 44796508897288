.u-phone-input {
    position: relative;
}

.u-phone-input-field input {
    height: calc(100% - 30px);
}

.u-phone-input-field {
    width: 100%;
    height: 56px;
    margin-top: 4px;
    box-sizing: border-box;
    border-radius: 12px;
    border: 1px solid rgba(206, 201, 219, 1);
    background-color: transparent;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    display: flex;
    align-items: center;
}

.u-phone-input-field.error {
    border-color: rgba(244, 54, 85, 1);
}


.u-phone-input-field-flag {
    font-size: 36px;
}

.u-phone-input-field-arrow-down {
    margin-left: 8px;
    width: 24px;
    height: 24px;
}

.u-phone-input-field-code {
    margin-left: 12px;
    font-size: 18px;
    line-height: 24px;
    color: rgba(28, 25, 38, 1);
}

.u-phone-input-label {
    display: block;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0;
    margin-bottom: 4px;
    color: rgba(85, 81, 101, 1);
}

.u-phone-input-submenu {
    position: absolute;
    top: 90px;
    width: 100%;
    z-index: 2;
    border: 1px solid rgba(206, 201, 219, 1);
    box-sizing: border-box;
    border-radius: 12px;
    background-color: #ffffff;
    max-height: 220px;
    overflow-y: auto;
}

.u-phone-input-submenu-element {
    height: 48px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 16px;
    padding-right: 24px;
    box-sizing: border-box;
    cursor: pointer;
}

.u-phone-input-submenu-element:hover {
    background-color: rgba(234, 228, 255, 1);
}

.u-phone-input-submenu-element-code {
    margin-left: auto;
    font-size: 16px;
    color: rgba(110, 106, 122, 1);
    line-height: 23px;
}

.u-phone-input-submenu-element-name {
    margin-left: 12px;
    font-size: 18px;
    color: rgba(28, 25, 38, 1);
    line-height: 24px;
}

.u-phone-input-submenu-element-flag {
    font-size: 36px;
}

.u-phone-input-field-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.u-phone-input-field-input {
    height: 50px;
    max-width: 100%;
    outline: none;
    font-size: 18px;
    line-height: 24px;
    color: rgba(28, 25, 38, 1);
    border: none;
    margin-left: 4px;
    background-color: transparent;
    font-family: 'Exo', sans-serif;
}

.u-phone-input-error {
    display: flex;
    margin-top: 4px;
    flex-direction: row;
    align-items: center;
}

.u-phone-input-error-message {
    color: rgba(244, 54, 85, 1);
    font-size: 14px;
    line-height: 18px;
}

.u-phone-input-error-icon {
    width: 18px;
    height: 18px;
    margin-right: 4px;
    display: inline-block;
}

