.code-confirm {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.code-confirm-input {
    width: 50px;
    height: 62px;
    margin-left: 12px;
    background-color: rgba(234, 228, 255, 1);
    border: 1px solid rgba(234, 228, 255, 1);
    border-radius: 5px;
    text-align: center;
    outline-color: rgba(101, 66, 221, 1);
    font-size: 20px;
    color: rgba(77, 93, 122, 1);
}

.code-confirm-input:first-child {
    margin-left: 0;
}
