@import '../../screen-sizes.scss';

.stats-elements {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 32px;
    gap: 32px;
    min-height: 400px;
    position: relative;
}

.stats-element {
    padding: 24px;
    box-sizing: border-box;
    border: 24px;
    background-color: rgba(255, 255, 255, 1);
    width: calc(50% - 16px);
    border-radius: 16px;
}

.stats-element-label {
    display: block;
    font-size: 24px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0;
}

.stats-element-content {
    margin-top: 16px;
    box-sizing: border-box;
    padding: 12px 16px;
    border: 1px solid rgba(242, 238, 255, 1);
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.stats-element-icon {
    width: 64px;
    height: 64px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stats-element-icon-image {
    width: 36px;
    height: 36px;
}

.stats-element-right {
    margin-left: 16px;
}

.stats-element-value {
    color: rgba(28, 25, 38, 1);
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0;
    display: block;
}

.stats-element-text {
    display: block;
    margin-top: 8px;
    color: rgba(110, 106, 122, 1);
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0;
}

.stats-switcher {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    column-gap: 36px;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    margin-top: 12px;
}

.stats-switcher-element {
    width: 200px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    padding: 12px 24px;
    box-sizing: border-box;
    cursor: pointer;
    color: rgba(110, 106, 122, 1);
    font-size: 24px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0;
}

@media only screen and (max-width: $MAX_M_WIDTH) {
    
    .stats-elements {
        padding: 0 32px;
    }

    .stats-switcher-element {
        font-size: 20px;
    }

}

@media only screen and (max-width: $MAX_S_WIDTH) {
    
    .stats-element {
        width: 100%;
    }

}