.search-area {
    height: 56px;
    background-color: rgba(252, 251, 255, 1);
    width: 100%;
    border-radius: 20px;
    position: relative;
}

.search-area-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 16px;
    top: 16px;
}

.search-area-input {
    width: calc(100% - 16px - 24px);
    margin-left: 40px;
    padding-left: 8px;
    padding-right: 16px;
    box-sizing: border-box;
    outline: none;
    height: 100%;
    background-color: transparent;
    border-radius: 20px;
    border: none;
    font-size: 18px;
    line-height: 23px;
    color: rgba(110, 106, 122, 1);
}

input:placeholder-shown {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
