@import './screen-sizes.scss';

body {
  font-family: 'Exo', sans-serif;
}

.container {
  max-width: 1200px;
  box-sizing: border-box;
}

@media only screen and (max-width: $MAX_L_WIDTH) {
  .container {
    max-width: 900px;
  }
}

@media only screen and (max-width: $MAX_M_WIDTH) {
  .container {
    max-width: 700px;
    padding: 0 20px;
  }
}

.loader {
  position: absolute;
  width: 48px;
  height: 48px;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}


.loader::after,
.loader::before {
  content: '';
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid #000000;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 2s linear infinite;
}

.loader::after {
  animation-delay: 1s;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}