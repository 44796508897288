@import '../../../screen-sizes.scss';

.tab {
    border-radius: 24px;
    width: 100%;
    margin-top: 16px;

    &:first-child {
        margin-top: 0;
    }

}

.tab-top {
    padding: 24px 32px 24px 32px;
    box-sizing: border-box;
    width: 100%;
    border-bottom: 1px solid rgba(205, 196, 235, 1);
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.tab-top-label {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    color: rgba(28, 25, 38, 1);
}

.tab-top-arrow {
    width: 24px;
    height: 24px;
    margin-left: auto;
}

.tab-content {
    padding-bottom: 24px;
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;
}

.tab.active .tab-top-arrow {
    transform: rotate(180deg);
}

.tab.active .tab-top {
    border-bottom: none;
}

.tab.active {
    background-color: rgba(234, 228, 255, 1);
}

@media only screen and (max-width: $MAX_M_WIDTH) {

    .tab-top {
        padding: 20px 28px;
        box-sizing: border-box;
        width: 100%;
        border-bottom: 1px solid rgba(205, 196, 235, 1);
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
    }

    .tab-top-label {
        font-size: 22px;
        font-weight: 500;
        line-height: 32px;
        color: rgba(28, 25, 38, 1);
    }


}
