.footer {
    height: 108px;
    background-color: rgb(252, 251, 255);
    display: flex;
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;

    .footer-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        height: 100%;
        width: 100%;
        margin: auto;

        .footer-logo-container {
            display: flex;
            flex-direction: column;

            .footer-copyright {
                font-size: 14px;
            }
        }

        .footer-menu-container {
            display: flex;
            margin-left: auto;

            .footer-menu-item {

                margin-left: 24px;

                &:first-child {
                    margin-left: 0;
                }

                a {
                    text-decoration: none;
                    color: #393545;

                }
            }
        }

        .footer-apps-container {
            display: flex;
            gap: 10px;
            margin-left: 12px;
            flex-wrap: wrap;
        }

    }

}

@media only screen and (max-width: 992px) {

    .footer {
        display: none;
    }

}
