.note-modal-icon {
  margin: auto;
  width: 65px;
  height: 65px;
}

.note-modal-title {
  margin-top: 24px;
  text-align: center;
  font-size: 24px;
  display: block;
  line-height: 32px;
  color: rgba(28, 25, 38, 1);
  font-weight: 500;
}

.note-modal-text {
  text-align: center;
  display: block;
  color: rgba(28, 25, 38, 1);
  font-size: 16px;
  line-height: 23px;
  max-width: 324px;
  margin: 16px auto 0;
}

.note-modal-input-area {
  margin-top: 16px;
}

.note-modal-input-area-label {
  display: block;
  color: rgba(28, 25, 38, 1);
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}

.note-modal-input-area-textarea {
  resize: none;
  width: 100%;
  border: 1px solid rgba(206, 201, 219, 1);
  border-radius: 12px;
  padding: 12px 16px;
  box-sizing: border-box;
  color: rgba(110, 106, 122, 1);
  font-size: 16px;
  line-height: 23px;
  margin-top: 12px;
  height: 120px;
}
