.messages-aside-block {
    background-color: #ffffff;
    padding: 24px 19px;
    box-sizing: border-box;
    border-radius: 16px;
    margin-top: 24px;
    overflow-y: auto;
}

.messages-aside-block-title {
    color: rgba(28, 25, 38, 1);
    font-size: 18px;
    line-height: 23px;
    display: block;
    font-weight: 600;
    text-align: center;
}

.messages-aside-block-elements {
    margin-top: 24px;
}
