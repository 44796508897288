.u-text-input {
    position: relative;
}

.u-text-input-field {
    width: 100%;
    height: 56px;
    margin-top: 4px;
    box-sizing: border-box;
    border-radius: 12px;
    border: 1px solid rgba(206, 201, 219, 1);
    background-color: transparent;
    outline-color: rgba(57, 53, 69, 1);
    color: rgba(28, 25, 38, 1);
    font-size: 18px;
    padding-left: 16px;
    padding-right: 54px;
    position: relative;
}

.u-text-input-field.error {
    border-color: rgba(244, 54, 85, 1);
}

.u-text-input-field::placeholder {
    color: rgba(110, 106, 122, 1);
}

.u-text-input-label {
    display: block;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0;
    margin-bottom: 4px;
    color: rgba(85, 81, 101, 1);
}

.u-text-input-bottom-text {
    display: block;
    margin-top: 10px;
    color: rgba(85, 81, 101, 1);
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
}

.u-text-input-icon {
    position: absolute;
    right: 16px;
    top: 48px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.u-text-input-error {
    display: flex;
    margin-top: 4px;
    flex-direction: row;
    align-items: center;
}

.u-text-input-error-message {
    color: rgba(244, 54, 85, 1);
    font-size: 14px;
    line-height: 18px;
}

.u-text-input-error-icon {
    width: 18px;
    height: 18px;
    margin-right: 4px;
    display: inline-block;
}
