.navigation-drawer {
  &-overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    background-color: transparent;
    overflow-x: hidden;

    &.active {
      width: 100%;
      background-color: #1111115c;
      transition: background-color 0.3s;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 0;
    height: 100%;
    background-color: white;

    &.active {
      width: 90%;
      max-width: 768px;
      transition: width 0.3s;
      overflow-x: hidden;
    }
  }
}
