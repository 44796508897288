@import '../../screen-sizes.scss';

.small-notifications-container {
  position: fixed;
  display: flex;
  width: 100%;
  align-items: end;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 100;
}

.small-notification {
  margin: 20px;
  max-width: 420px;
  min-height: 56px;
  border-radius: 16px;
  padding: 16px 52px 16px 66px;
  position: relative;
  box-sizing: border-box;
  margin-top: 10px;
}

.small-notification.error {
  background-color: rgba(251, 231, 234, 1);
  border: 1px solid rgba(240, 49, 49, 1);
}

.small-notification.success {
  background-color: rgba(236, 255, 242, 1);
  border: 1px solid rgba(30, 175, 70, 1);
}

.small-notification:first-child {
  margin-top: 0;
}

.small-notification-title {
  display: block;
  color: rgba(28, 25, 38, 1);
  font-size: 16px;
  line-height: 23px;
  font-weight: 500;
}

.small-notification-message {
  display: block;
  margin-top: 4px;
  color: rgba(57, 53, 69, 1);
  font-size: 16px;
  line-height: 23px;
}

.small-notification-close-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.small-notification-icon {
  left: 24px;
  top: 16px;
  width: 30px;
  height: 30px;
  position: absolute;
}

@media only screen and (max-width: $MAX_M_WIDTH) {
  
  .small-notification {
    padding: 12px 52px 12px 55px;
  }

  .small-notification-icon {
    left: 14px;
    top: 16px;
    width: 30px;
    height: 30px;
    position: absolute;
  }

}
