@import '../../../screen-sizes.scss';

.location-select {
    display: flex;
    row-gap: 24px;
    margin-top: 32px;
    width: 100%;
    max-width: 670px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .location-select-inner {
        min-width: 300px;
        position: relative;
    }

    .location-select-field {
        width: 100%;
        height: 56px;
        border: 1px solid rgba(205, 196, 235, 1);
        border-radius: 20px;
        padding-left: 16px;
        box-sizing: border-box;
    }

    .location-select-field-icon {
        width: 24px;
        position: absolute;
        left: 16px;
        top: 16px;
        height: 24px;
        z-index: 10;
    }

    .location-select-field-input {
        font-size: 18px;
        color: rgba(28, 25, 38, 1);
        border: none;
        outline: none;
        position: absolute;
        top: 3px;
        left: 1px;
        border-radius: 20px;
        padding: 0;
        padding-left: 48px;
        padding-right: 16px;
        width: calc(100% - 66px);
        height: 48px;
        z-index: 2;
    }

    .location-select-map {
        width: 100%;
        height: 303px;
        border-radius: 16px;
    }

    .location-select-submenu {
        position: absolute;
        top: 0;
        padding-top: 56px;
        width: 100%;
        left: 0;
        border-radius: 20px;
        background-color: #ffffff;
        border: 1px solid rgba(205, 196, 235, 1);
        padding-bottom: 23px;
        z-index: 1;
    }

    .location-select-submenu-element {
        height: 20px;
        padding-left: 48px;
        box-sizing: border-box;
        padding-right: 16px;
        font-size: 14px;
        color: rgba(110, 106, 122, 1);
        line-height: 20px;
        cursor: pointer;
    }

}

@media only screen and (max-width: $MAX_M_WIDTH) {

    .location-select {

    }

}
