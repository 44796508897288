@import '../../../screen-sizes.scss';

.photos-input-placeholder {
    background-color: rgba(234, 228, 255, 1);
    border-radius: 24px;
    width: 100%;
    max-width: 450px;
    height: 191px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 32px;
    flex-direction: column;
}

.photos-input-placeholder-icon {
    width: 56px;
    height: 56px;
}

.photos-input-placeholder-text {
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    font-weight: 500;
    color: rgba(101, 66, 221, 1);
    max-width: 250px;
    margin-top: 12px;
}

.photos-input-placeholder-sub-text {
    display: block;
    margin-top: 24px;
    color: rgba(110, 106, 122, 1);
    font-size: 14px;
    line-height: 20px;
}

.photos-input {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 15px;
    justify-content: center;
    margin-top: 32px;
}

.photos-input-element {
    width: 144px;
    height: 144px;
    background-color: rgba(234, 228, 255, 1);
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}

.photos-input-element:first-child {
    margin-left: 0;
}

.photos-input-element svg {
    width: 56px;
    height: 56px;
}

.photos-input-element-photo {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 24px;
}

.photos-input-element-fade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 24px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 3;
    display: none;
}

.photos-input-element-fade-icon {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    cursor: pointer;
}


.photos-input-element-fade-icon:first-child {
    margin-left: 0;
}

.photos-input-element:hover .photos-input-element-fade {
    display: flex;
}

.photos-input-single-photo {
    margin-top: 32px;
    width: 280px;
    height: 280px;

    & .photos-input-element {
        width: 280px;
        height: 280px;
    }

}

.crop-modal-top {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
}

.crop-modal-top-title {
    font-size: 24px;
    line-height: 32px;
    color: rgba(28, 25, 38, 1);
    font-weight: 500;
    text-align: center;
    display: block;
}

.crop-modal-bottom {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.crop-modal-content {
    height: 300px;
    width: 300px;
    margin: auto;
    margin-top: 16px;
}

.cropper {
    width: 100%;
    height: 100%;
}

.crop-modal-image {
    width: 100%;
    height: 100%;
    border-radius: 16px;
}

.crop-modal-loader {
    width: 100%;
    height: inherit;
    position: relative;
    border-radius: 16px;
    border: 1px solid rgba(206, 201, 219, 1);
    background-color: rgba(206, 201, 219, 0.2);
}

.photos-input-element-label {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 2;
    padding: 2px 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border-radius: 16px;

    &.on-review {
        background-color: rgba(255, 194, 78, 1);
        color: rgba(85, 81, 101, 1);
    }

    &.explicit {
        background-color: rgba(206, 0, 9, 1);
        color: rgba(252, 251, 255, 1);
    }

    &.approved {
        background-color: rgba(30, 175, 70, 1);
        color: rgba(252, 251, 255, 1);
    }

}

@media only screen and (max-width: $MAX_M_WIDTH) {

    .crop-modal-content {
        max-width: 400px;
    }

    .cropper {
        max-width: 400px;
    }

    .crop-modal-image {
        max-width: 400px;
    }

    .crop-modal-loader {
        max-width: 400px;
    }

}
