
.basic-button {
  border: none;
  color: #FFF;
  background-color: #6542DD;
  border-radius: 50px;
  padding: 12px 30px;
  font-size: 16px;
  cursor: pointer;
}

.btn-outlined {
  background-color: #FFF;
  border: 1px solid #6542DD;
  color: #6542DD;
}
